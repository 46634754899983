class StorageService {
  static readonly languageName = 'lang';
  static readonly loginUrl = 'loginUrl';

  public static getLang(): string {
    return localStorage.getItem(StorageService.languageName) ?? 'en';
  }

  public static setLang(lang: string | undefined): void {
    localStorage.setItem(StorageService.languageName, lang || 'en');
  }

  public static getLoginUrl(): string {
    return localStorage.getItem(StorageService.loginUrl) ?? '/auth/login';
  }

  public static setLoginUrl(route?: string): void {
    localStorage.setItem(StorageService.loginUrl, route || '/auth/login');
  }
}

export default StorageService;
