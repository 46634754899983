import { createContext, useCallback, useContext, useState } from 'react';

type ImageCacheType = {
  getImage: (fileId: string) => string | null;
  setImage: (fileId: string, url: string) => void;
};

const ImageCacheContext = createContext<ImageCacheType>(null!);

export const ImageCacheProvider = ({ children }: { children: React.ReactNode }) => {
  const [cache, setCache] = useState<Record<string, string>>({});

  const getImage = useCallback((fileId: string) => cache[fileId] || null, [cache]);
  const setImage = useCallback((fileId: string, url: string) => setCache((prev) => ({ ...prev, [fileId]: url })), []);

  return <ImageCacheContext.Provider value={{ getImage, setImage }}>{children}</ImageCacheContext.Provider>;
};

export const useImageCache = () => useContext(ImageCacheContext);
