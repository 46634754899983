/* eslint-disable @typescript-eslint/no-explicit-any */
import { Children, PropsWithChildren, ReactElement } from 'react';

type FnType = (() => JSX.Element | null) & { isEmpty: boolean };

const addInfoToFn = (fn: () => any, isEmpty: boolean): FnType => {
  (fn as any).isEmpty = isEmpty;
  return fn as any;
};

const useSlot = <TProps extends PropsWithChildren>(
  props: TProps,
  name: TProps extends { _slots: infer TSlot } ? TSlot : never,
  fallback?: ReactElement<any, any> | null,
): FnType => {
  const childrenArr: any[] = Children.toArray(props.children);
  const predicate = (child: any) => {
    return child?.props?.name === name;
  };

  if (childrenArr.some(predicate)) {
    const children = childrenArr.find(predicate)?.props?.children;
    return addInfoToFn(() => children, Children.count(children) === 0);
  }

  if (fallback) {
    return addInfoToFn(() => fallback, !fallback);
  }

  function EmptyPlaceholder() {
    return null;
  }
  return addInfoToFn(EmptyPlaceholder, true);
};

export default useSlot;
